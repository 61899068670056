import React from 'react'
import { Layout, BackTop } from 'antd'
import { withRouter } from 'react-router-dom'
import { ContainerQuery } from 'react-container-query'
import DocumentTitle from 'react-document-title'
import classNames from 'classnames'
import urls from '../utils/urls'

import SiderMenu from '../components/SiderMenu'
import logo from '../assets/koala_logo.png'

import { urlToList } from '../components/_utils/pathTools'
import KoalaGlobalHeader from '@koala/global-header'
import './BasicLayout.less'
import util from '../utils/util'
import Routes from './Routes'
import { FormCollapseContext } from '../KoalaComponents/KoalaTable/constants'
import getAuthUserInfo from '../services/gateWayRequest'

const { Content } = Layout

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
}

// interface Props extends Partial<RouteComponentProps> {}

/* 不校验身份（cookie）的url地址 */
const notCheckUrlList = [
  '/',
  '/apply',
  '/travelInfoDetail',
  '/details',
  '/travel/details',
  '/audit',
  '/travel-list',
  '/convention-apply',
  '/agreement/charge/detail/referNo',
  '/entertain/detail',
]

/* 只显示页面内容的url列表 */
const isHideSilderAndHeaderUrlList = [
  // '/travel/details',
  '/audit',
  '/travel-list',
  // '/agreement/charge/detail/referNo',
  // '/entertain/detail',
]

// /*  */
class BasicLayout extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: {},
      collapsed: false,
      isPdf: false,
      isHideSilderAndHeader: false,
      privilegeTree: [],
      formCollapsed: false,
      isHideSiderMenu: false,
    }
  }

  componentDidMount() {
    // 获取用户信息、权限
    /* pdf预览页面不需要校验权限 */
    if (isHideSilderAndHeaderUrlList.includes(window.location.pathname)) {
      this.setState({ isPdf: true, isHideSilderAndHeader: true })
      if (
        window.location.pathname === '/travel/details' ||
        window.location.pathname === '/audit' ||
        window.location.pathname === '/travel-list' ||
        window.location.pathname === '/agreement/charge/detail/referNo' ||
        window.location.pathname === '/departmental-budget-management-detail'
      ) {
        this.getUserInfo()
      }
    } else {
      this.getUserInfo()
    }
    // /contract
  }

  getUserInfo = () => {
    getAuthUserInfo()
      .then((res: any) => {
        if (res) {
          const result = res

          const user = result
          // console.log(user)
          user.avatar =
            'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'

          // 保存用户信息
          util.setStorage('userInfo', user)

          this.setState({
            currentUser: user,
            privilegeTree: user.privilegeTree,
          })

          this.checkPrivilege(user.privilegeTree)
        }
      })
      .catch((error: any) => {
        console.log(error)

        // 请求配置发生的错误
        if (!error.response) {
          this.props.history.push({
            pathname: '/home',
          })
          return null
        }
      })
  }

  // 权限检查
  checkPrivilege(privilegeTree) {
    const pathName = window.location.pathname
    const flag = privilegeTree.some((item) => {
      return item.moduleUrl === pathName || `/${item.moduleUrl}` === pathName
    })

    if (!flag && !notCheckUrlList.includes(pathName)) {
      // console.log(pathName)
      window.location.pathname = ''
    }
  }

  // 页面标题
  getPageTitle() {
    return '费控系统'
  }

  // 菜单折叠
  handleMenuCollapse = (collapsed) => {
    this.setState({
      collapsed: collapsed,
    })
  }

  // 退出登录
  handleMenuClick = () => {
    window.location.href = urls.LOGIN + '/account/logout'
    util.removeStorage('userInfo')
  }

  setFormCollapsed = (b) => {
    this.setState({
      formCollapsed: b,
    })
  }

  headerRenderAction = (actions) => {
    actions = actions.filter(({ key }) => {
      // 内置的key有index（首页）、campus（考拉家园）、mall（考拉商城）、notify（通知公告）、aichat（智能客服）、serviceChat（人工客服）和userInfo（用户信息）
      return key !== 'mall'
    })
    return actions
  }

  handleHideSiderMenu = (isHide: boolean) => {
    this.setState({ isHideSiderMenu: isHide })
  }

  render() {
    const { currentUser, collapsed, privilegeTree } = this.state
    // console.log(currentUser)
    const {
      location: { pathname },
    } = this.props
    const { isHideSilderAndHeader, isHideSiderMenu } = this.state
    // 左侧导航参数
    const path =
      pathname === '/' ? urlToList('/Home/Index') : urlToList(pathname)

    // console.log(path)
    const layout = (
      <Layout>
        {!(isHideSilderAndHeader || isHideSiderMenu) && (
          <SiderMenu
            logo={logo}
            collapsed={collapsed}
            currentUser={currentUser}
            onCollapse={this.handleMenuCollapse}
            path={path}
          />
        )}

        <Layout>
          {!isHideSilderAndHeader && (
            <>
              <KoalaGlobalHeader
                systemName="考拉费控系统"
                siderCollapse={collapsed}
                triggerSiderCollapse={this.handleMenuCollapse}
                env={
                  process.env.BUILD_ENV === 'localhost'
                    ? 'demo'
                    : process.env.BUILD_ENV === 'demo'
                      ? 'demo'
                      : 'testing'
                }
                systemKey="CCSYS"
                notifySource={10}
                renderAction={this.headerRenderAction}
              ></KoalaGlobalHeader>
            </>
          )}
          <FormCollapseContext.Provider
            value={{
              collapsed: this.state.formCollapsed,
              triggerCollapse: this.setFormCollapsed,
              handleHideSiderMenu: this.handleHideSiderMenu,
            }}
          >
            <Content
              style={
                !isHideSilderAndHeader
                  ? {
                      height: '100%',
                      padding: '16px 16px 0 16px',
                      borderRadius: '6px',
                    }
                  : { backgroundColor: '#fff' }
              }
            >
              {/* <Routes /> */}
              <Routes privilegeTree={privilegeTree} />
            </Content>
          </FormCollapseContext.Provider>
          <BackTop visibilityHeight={100} />
        </Layout>
      </Layout>
    )

    return (
      <DocumentTitle title={this.getPageTitle()}>
        <ContainerQuery query={query}>
          {(params) => (
            <div className={'ehi-wrap ' + classNames(params)}>{layout}</div>
          )}
        </ContainerQuery>
      </DocumentTitle>
    )
  }
}

export default withRouter(BasicLayout)
