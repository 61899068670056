// 页面监控
import Logger, { koalaLoggerDefaultConfig } from '@ehi/logger'
import AliLogger from '@aliyun-sls/web-track-browser'
import { getCookieData } from '@ehi/utils'

const buildEnv = process.env.BUILD_ENV
const grayEnv = process.env.GRAY_ENV
const nodeEnv = process.env.NODE_ENV
/**
 *  阿里云监控
 *  https://help.aliyun.com/document_detail/31752.html?spm=a2c4g.11186623.6.810.7e8b1b63denuGo
 */
const aliLogger = new AliLogger({
  ...koalaLoggerDefaultConfig,
  // 自定义覆盖配置
})
export const logger = new Logger({
  /** 区分不同环境用来分析筛选 */
  buildEnv: buildEnv || 'localhost',
  /** 项目名 */
  webProjectName: 'koalaCostControlWeb',
  groupName: 'koala-front',
  /** 调试模式下将console.log上送的日志数据*/
  debugMode: nodeEnv === 'development',
  loggerIns: aliLogger,
})
logger.registerPage({
  version: process.env.BUILD_VERSION,
  gray: grayEnv || 'normal',
})
// 启动监控
logger.initWebLog()
// 自定义上送逻辑 trackTrace('addOrder', { orderId: '8888' })
export function koalaTrackTrace(message: string, data: any) {
  logger.trackTrace({
    message,
    data,
  })
}

export function trackBtnEvent() {
  window.addEventListener('click', (e) => {
    const { UserCode, UserName } = getCookieData()

    // 获取点击的元素
    const target = e.target as HTMLElement
    const innerText = (target.innerText || '').replace(/\s/g, '')
    const traceTexts = ['确定', '确认', '提交']
    // 筛选文本为确定、确认和提交的元素
    const isSubmitItem = traceTexts.some((text) => {
      return innerText.includes(text)
    })
    // 筛选data-track-id属性的元素
    const trackId = target.dataset?.trackId

    if (isSubmitItem && !!trackId) {
      // 上送日志
      koalaTrackTrace('bussinessLog', {
        orderNo: trackId,
        eventName: innerText,
        oprNo: UserCode || '',
        oprName: UserName || '',
        oprTime: new Date(),
      })
    }
  })
}
